<template>
  <div class="top-slides second-zone">
    <b-skeleton-wrapper :loading="isSlidesLoading">
      <template #loading>
        <div class="top-slides__item">
          <b-skeleton-img height="260px"></b-skeleton-img>
        </div>
      </template>
      <div v-if="slides && slides.length" v-bind="settings" class="second_slides__wrap">
        <div
          v-for="slide in slides"
          :key="slide.id"
          class="top-slides__item second-zone__item"
          @click="redirect(slide.url)"
        >
          <div class="top-slides__item second-zone__item" :style="{ backgroundImage: `url(${slide.path})` }"></div>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'SecondZoneSlides',
  components: {
  },

  data: () => ({
    isSlidesLoading: false,
    settings: {
      dots: true,
      dotsClass: 'slick-dots custom-dot-class',
      edgeFriction: 0.35,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },

      ],
    },
  }),

  computed: {
    slides() {
      return this.$store.state.secondZoneSlides
    },
  },

  async mounted() {
    if (this.slides.length) return
    try {
      this.isSlidesLoading = true
      const { banners } = await this.wrapAndHandleRequest(() => Api.getTopSlides('zone=2'), false)
      this.$store.commit('SET_SECOND_ZONE_SLIDES', banners)
    } finally {
      this.isSlidesLoading = false
    }
  },

  methods: {
    redirect(url) {
      if (!url) return
      const link = document.querySelector('.invisibleLink')
      link.setAttribute('href', url)
      link.click()
    },
  },
}
</script>
